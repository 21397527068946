/* You can add global styles to this file, and also import other style files */

p {
  font-family: 'Roboto', 'Lato', sans-serif;
}

.responsive-img {
  max-width: 100%;
  height: auto;
  display: block;
}

.td-data-table-cell {
  overflow: hidden;
  text-overflow: ellipsis;
}

.td-dialog-title {
  font: 500 20px/32px Roboto,"Helvetica Neue",sans-serif;
}

.td-dialog-content, .td-dialog-message, .mat-dialog-content {
  font-family: 'Roboto', 'Lato', sans-serif;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
  background-color: white;
  padding-top: 10px;
  font-family: 'Roboto', 'Lato', sans-serif;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-placeholder {
  background-color: white;
  opacity: 0;
}

